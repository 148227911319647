import axiosInstance from "./axiosInstance";

const documentService = {
    fetchAllDocuments: async (customer) => {
        try {
            const response = await axiosInstance.get('/documents/customer/' + customer);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
}

export default documentService