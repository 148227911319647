import React from 'react'
import { useLocation } from 'react-router-dom'
import Navbar from './Navbar/Navbar'
import Footer from './Footer/Footer'

const Layout = ({ children }) => {

    const location = useLocation()

    return (
        <>
            {location.pathname === '/login' || location.pathname.toLowerCase().includes('/crm') || location.pathname === '/signup' ? (
                children
            ) :
                <div className='flex flex-row'>
                    <Navbar />
                    <div className=' w-full'>
                        <div> <main> {children} </main></div>
                        <div><Footer /> </div>
                    </div>
                </div>
            }
        </>

    )
}

export default Layout
