import React from "react";
import engine from "../assests/engine.png";
import { useNavigate } from "react-router-dom";

const RegisterForm = () => {
    const navigate = useNavigate()
    return (
        <div className="py-6">
            <div className="grid md:grid-cols-2 grid-cols-1">
                <div className="flex justify-center flex-col text-center">
                    <h1 className="text-secondary text-4xl font-extrabold">
                        Register Now!
                    </h1>
                    <p className="flex justify-center text-center self-center text-md text-gray-500 font-medium mt-5 w-[70%]">
                        Unlock exclusive discounts and access to the latest autoparts by
                        registering on our website!
                    </p>
                </div>
                <div className="col-span-1 flex justify-center">
                    <img src={engine} alt="img"
                        className="flex justify-center self-center mt-10 md:mt-0 md:h-auto h-52 w-auto" />
                </div>
            </div>
            <div className="flex justify-center mt-10">
                <button
                    onClick={() => navigate('/signup')}
                    className="rounded-lg border hover:bg-white hover:border-primary duration-300 hover:text-primary font-semibold bg-primary text-white px-10 py-2">
                    Register
                </button>
            </div>
        </div>
    );
};

export default RegisterForm;
