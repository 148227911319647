import axiosInstance from "./axiosInstance";

const productService = {
    fetchAll: async () => {
        try {
            const response = await axiosInstance.get('/products/all');
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    searchByVendor: async (vendor) => {
        try {
            const response = await axiosInstance.get('/products/search/' + vendor);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    fetchTotal: async () => {
        try {
            const response = await axiosInstance.get('/products/total');
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
}

export default productService;
