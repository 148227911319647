import axiosInstance from "./axiosInstance";

const brandService = {
    create: async (payload) => {
        try {
            const response = await axiosInstance.post('/brands/create', payload);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    fetchAllBrands: async () => {
        try {
            const response = await axiosInstance.get('/brands/all');
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    fetchTotal: async () => {
        try {
            const response = await axiosInstance.get('/brands/total');
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    delete: async (id) => {
        try {
            const response = await axiosInstance.delete('/brands/delete/' + id);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
}

export default brandService