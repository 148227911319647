import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from "moment";
import React, { useEffect, useState } from "react";
import orderService from "../../Services/orderService";
import Loading from "./Loading";
import { CgClose } from 'react-icons/cg';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '12px'
};

export default function OrderDetailsView({ open, handleClose, handleOpen, singleOrder }) {

    const [order, setOrder] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const navigate = useNavigate()
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    useEffect(() => {
        const getOrders = async () => {
            setLoading(true);
            try {
                if (singleOrder?.invoice) {
                    const response = await orderService.fetchByInvoice(singleOrder.invoice);
                    setOrder(response.order);
                }
            } catch (e) {
                console.error("Failed to fetch Orders", error);
                setError(true);
            }
            setLoading(false);
        };

        getOrders();
    }, [singleOrder]);

    const OpenModal = (order) => {
        setSelectedOrder(order);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedOrder(null);
    };

    const handleStatus = () => {
        let status = ""
        if (order.status === "pending") {
            status = "confirmed"
        } else if (order.status === "confirmed") {
            status = "underprocess"
        } else if (order.status === "underprocess") {
            status = "readytodeliver"
        } else if (order.status === "readytodeliver") {
            status = "dispatched"
        } else if (order.status === "dispatched") {
            status = "deliveredorders"
        }
        orderService.updateStatus(order.id, { status }).then(response => {
            if (response.responseCode === 200) {
                navigate('/order')
            }
        })
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className=''
            >
                <Box sx={style}>
                    <div className='flex justify-end'>
                        <CgClose className='cursor-pointer m-2 text-red-600' onClick={handleClose} />
                    </div>
                    <div className="my-4 mx-10 lg:mx-12">
                        {loading ?
                            <div className="mt-20">
                                <Loading />
                            </div>
                            :
                            order ? (
                                <div>
                                    <div className="flex flex-row justify-between mt-4 border-b-2 border-gray-300">
                                        <div className="flex flex-row items-center">
                                            <h1 className="font-bold text-2xl text-primary animate__animated animate__fadeInLeft">
                                                {order.invoice}
                                            </h1>
                                            <p className="ml-5">
                                                <p className="bg-red-600 px-4 py-[1px] rounded-md text-white text-[10px]">
                                                    {order.status === "pending" ? "Confirm Order" : order.status === "confirmed" ? "Under Process" : order.status === "underprocess" ? "Ready To Deliver" : order.status === "readytodeliver" ? "Dispatch Order" : order.status === "dispatched" ? "Mark as Delivered" : "Delivered"}
                                                </p>
                                            </p>
                                            <p className="ml-5">
                                                <p className="bg-blue-600 px-4 py-[1px] rounded-md text-white text-[10px]">
                                                    {order.order_type}
                                                </p>
                                            </p>
                                        </div>
                                        <div className="flex flex-row justify-between gap-3 py-1">

                                            {/* <button className="bg-primary text-white py-1 px-2 rounded-md hover:bg-green-700"
                                                onClick={() => OpenModal(order)}
                                            > Add Payment </button> */}
                                            {/* <button className="bg-primary py-1 px-2 m-2 rounded-md text-white hover:bg-green-600"> Update Order Status </button> */}
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-4 w-[100%] mt-4">
                                        <p className="flex flex-col font-semibold text-sm text-green-600">
                                            Order Created At :{" "}
                                            <span className="text-gray-500 font-medium">
                                                {moment(order?.created_at).format("LL")}
                                            </span>
                                        </p>
                                        <p className="flex flex-col font-semibold text-sm text-green-600">
                                            Order Delivery Date:{" "}
                                            <span className="text-gray-500 font-medium">
                                                {moment(order?.delivery_date).format("LL")}
                                            </span>
                                        </p>
                                        <p className="flex flex-col font-semibold text-sm text-blue-600">
                                            Order Tracking Number :{" "}
                                            <span className="text-gray-500 font-medium">
                                                {order.tracking_number}
                                            </span>
                                        </p>
                                        <p className="flex flex-col font-semibold text-sm text-blue-600">
                                            Payment Status :{" "}
                                            <span className="text-gray-500 font-medium">
                                                {order.payment_status}
                                            </span>
                                        </p>

                                    </div>

                                    <h1 className="font-bold text-lg mt-8"> Customer Details </h1>
                                    <div className="flex flex-row gap-8 mt-2">
                                        <div>
                                            <span className="block leading-none font-semibold">Customer Name</span>
                                            <span className="text-sm leading-none font-medium text-gray-500"> {order.recipient} </span>
                                        </div>

                                        <div>
                                            <span className="block leading-none font-semibold">Customer Email</span>
                                            <span className="text-sm leading-none font-medium text-gray-500 "> {order.email} </span>
                                        </div>

                                        <div>
                                            <span className="block leading-none font-semibold">Customer Phone</span>
                                            <span className="text-sm leading-none font-medium text-gray-500"> {order.phone} </span>
                                        </div>

                                    </div>
                                    {order.receipt !== "0" && (
                                        <div className="mt-4 text-red-500 text-lg hover:text-blue-500"> <a href={order.receipt} target="blank" rel='noopener noreferrer' > Click to View Receipt  </a> </div>

                                    )}

                                    <div className="mt-2">
                                        <div className=' bg-white rounded-xl'>
                                            <div className=' text-center pt-5 font-bold text-xl'> Order Details</div>
                                            <div className='px- py-1 overflow-y-scroll max-h-[400px] pr-2'>
                                                <table className='  border-separate border-spacing-y-2'>
                                                    <thead>
                                                        <tr className="uppercase w-full text-white ">
                                                            <th className="py-[2%] border-b-[2px] rounded-l-xl text-left border-white bg-primary text-white font-bold text-[12px] pl-4  w-[3%]">
                                                                Id
                                                            </th>
                                                            <th className="py-[2%] border-b-[2px] border-white bg-primary text-white  text-left font-bold text-[12px]  pl-4 w-[3%]">
                                                                Vendor Code
                                                            </th>
                                                            <th className="py-[2%] border-b-[2px] border-white bg-primary text-white text-left font-bold text-[12px]  pl-4 w-[3%]">
                                                                Brand
                                                            </th>
                                                            <th className="py-[2%] border-b-[2px] border-white bg-primary text-white text-left font-bold text-[12px]  pl-4 w-[3%]">
                                                                Weight
                                                            </th>
                                                            <th className="py-[2%] border-b-[2px] border-white bg-primary text-white text-left font-bold text-[12px]  pl-4 w-[3%]">
                                                                Price
                                                            </th>
                                                            <th className="py-[2%] border-b-[2px]  border-white bg-primary text-white text-left font-bold text-[12px]  pl-4 w-[3%]">
                                                                Quantity
                                                            </th>
                                                            <th className="py-[2%] border-b-[2px] rounded-r-xl border-white bg-primary text-white text-left font-bold text-[12px]  pl-4 w-[3%]">
                                                                Total Price
                                                            </th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {order.details && order.details.map((detail, index) => (
                                                            <tr key={index} className='bg-gray-100 text-left text-sm'>
                                                                <td className='pl-4 w-[3%] py-2 rounded-l-xl font-light'>{detail.id}</td>
                                                                <td className='pl-4 w-[3%] py-2 text-[11px] '>{detail.vendor_code}</td>
                                                                <td className='pl-4 w-[3%] py-2 text-[12px] font-bold'>{detail.brand}</td>
                                                                <td className='pl-4 w-[3%] py-2 text-[12px]'>{detail.weight}</td>
                                                                <td className='pl-4 w-[3%] py-2 text-[12px]'>{detail.price} AED</td>
                                                                <td className='pl-4 w-[3%] py-2  text-[12px]'>{detail.quantity}</td>
                                                                <td className='pl-4 w-[3%] py-2 rounded-r-xl text-[12px]'>{detail.quantity * detail.price} AED</td>
                                                            </tr>

                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="flex justify-end mt-8">
                                        <div className={`flex flex-row bg-gray-100 px-8 py-2 rounded-xl`}>
                                            <div className={`flex flex-row`}>
                                                <p className={`text-black font-light`}>Order Amount:</p>
                                                <p className={`ml-3 text-primary font-bold`}>{order.amount}</p>
                                            </div>
                                            <div className={`w-[1px] h-full bg-black ml-5`} />
                                            <div className={`flex flex-row ml-5`}>
                                                <p className={`text-black font-light`}>Remaining Amount:</p>
                                                <p className={`ml-3 text-primary font-bold`}>{parseFloat(order.amount - order.paid_amount).toFixed(2)}</p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <OrderPayment
                                        isOpen={isModalOpen}
                                        onClose={closeModal}
                                        order={selectedOrder}
                                    /> */}

                                </div>

                            ) : (
                                <div className="text-center text-4xl text-black">The Order you are looking for is currently unavailable </div>
                            )
                        }
                    </div>
                </Box>
            </Modal>
        </div >
    );
}