import React, { useState } from 'react';
import { HiMenu } from 'react-icons/hi';
import { IoLogOutOutline } from "react-icons/io5";
import Sidebar from '../Sidebar/Sidebar';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const Topbar = ({ toggleSidebar }) => {
    const navigate = useNavigate('')
    const handleLogout = () => {
        navigate('/')
    }

    return (
        <div className='flex justify-between items-center h-full lg:px-12 px-4 bg-white'>
            <div className='flex items-center gap-4'>
                <button className='lg:hidden block' onClick={toggleSidebar}><HiMenu /></button>
                <h1 className='text-2xl font-bold text-center hover:cursor-pointer'>DBS Customer Portal</h1>
            </div>
            <div className='hover:cursor-pointer'
                onClick={handleLogout}
            >
                <IoLogOutOutline className='w-7 h-7' />
            </div>
        </div>
    );
};

export default Topbar;
