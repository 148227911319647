import React from "react";

const News = () => {
  const posts = [
    {
      title: "How Do Presidential Elections Impact the Housing Market?",
      date: "Jul 12, 2024",
      description: "Are you wondering if the upcoming election will have an impact on the housing market?",
      image: "https://via.placeholder.com/400x200.png?text=Election+Impact",
    },
    {
      title: "Why Moving to a Smaller Home After Retirement Makes Life Easier",
      date: "Jul 11, 2024",
      description: "Retirement is a time for relaxation, adventure, and enjoying the things you love.",
      image: "https://via.placeholder.com/400x200.png?text=Smaller+Home",
    },
    {
      title: "The Price of Perfection: Don’t Wait for the Perfect Home",
      date: "Jul 10, 2024",
      description: "In life, patience is a virtue—but waiting too long in homebuying isn’t wise.",
      image: "https://via.placeholder.com/400x200.png?text=Perfect+Home",
    },
    {
        title: "How Do Presidential Elections Impact the Housing Market?",
        date: "Jul 12, 2024",
        description: "Are you wondering if the upcoming election will have an impact on the housing market?",
        image: "https://via.placeholder.com/400x200.png?text=Election+Impact",
      },
      {
        title: "Why Moving to a Smaller Home After Retirement Makes Life Easier",
        date: "Jul 11, 2024",
        description: "Retirement is a time for relaxation, adventure, and enjoying the things you love.",
        image: "https://via.placeholder.com/400x200.png?text=Smaller+Home",
      },
      {
        title: "The Price of Perfection: Don’t Wait for the Perfect Home",
        date: "Jul 10, 2024",
        description: "In life, patience is a virtue—but waiting too long in homebuying isn’t wise.",
        image: "https://via.placeholder.com/400x200.png?text=Perfect+Home",
      },
  ];

  return (
    <section className="py-10 bg-gray-100 p-5">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold text-center mb-8 mt-12">Our Posts</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {posts.map((post, index) => (
            <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden">
             
              <div className="p-6">
                <h3 className="font-semibold text-lg mb-2">{post.title}</h3>
                <p className="text-sm text-gray-500">{post.date}</p>
                <p className="text-gray-700 my-3">{post.description}</p>
                {/* <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
                  Read more
                </button> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default News;
