export const LoadingDots = () =>
    <div>
        <style>
            {`
          @keyframes blink {
            0% {
              opacity: 0.2;
            }
            20% {
              opacity: 1;
            }
            100% {
              opacity: 0.2;
            }
          }
        `}
        </style>
        <span
            style={{
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                lineHeight: '1.75'
            }}
        >
            <span
                style={{
                    backgroundColor: 'white',
                    borderRadius: '9999px',
                    height: '0.5rem',
                    width: '0.5rem',
                    animation: 'blink 1.4s infinite both',
                    margin: '0 0.125rem'
                }}
            />
            <span
                style={{
                    backgroundColor: 'white',
                    borderRadius: '9999px',
                    height: '0.5rem',
                    width: '0.5rem',
                    animation: 'blink 1.4s infinite both',
                    margin: '0 0.125rem',
                    animationDelay: '0.2s'
                }}
            />
            <span
                style={{
                    backgroundColor: 'white',
                    borderRadius: '9999px',
                    height: '0.5rem',
                    width: '0.5rem',
                    animation: 'blink 1.4s infinite both',
                    margin: '0 0.125rem',
                    animationDelay: '0.4s'
                }}
            />
        </span>
    </div>
