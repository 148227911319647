import axiosInstance from "./axiosInstance";

const customerService = {
    create: async (payload) => {
        try {
            const response = await axiosInstance.post('/customers/create', payload);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    createTemporary: async (payload) => {
        try {
            const response = await axiosInstance.post('/customers/createTemporary', payload);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    login: async (payload) => {
        try {
            const response = await axiosInstance.post('/customers/login', payload);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    fetchSingle: async (email) => {
        try {
            const response = await axiosInstance.get('/customers/single/' + email);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    fetchAll: async () => {
        try {
            const response = await axiosInstance.get('/customers/all');
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    updateProfile: async (id, payload) => {
        try {
            const response = await axiosInstance.put('/customers/updateProfile/' + id, payload);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    updateCompanyProfile: async (id, payload) => {
        try {
            const response = await axiosInstance.put('/customers/updateCompanyProfile/' + id, payload);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    updatePassword: async (email, payload) => {
        try {
            const response = await axiosInstance.put('/customers/changePassword/' + email, payload);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    status: async (id) => {
        try {
            const response = await axiosInstance.put('/customers/status/' + id);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
}

export default customerService