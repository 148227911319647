import React from "react";
import carImage from "../../assests/aboutus.jpg";
import "animate.css";

const AboutUs = () => {
  return (
    <div className="pb-20 pt-32">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:px-20 px-0 mb-8">
        <div className="flex justify-center flex-col text-center animate__animated animate__fadeInLeft">
          <h1 className="text-4xl font-extrabold text-secondary mb-5">
            Our Mission <span className="text-primary">And Vision</span>
          </h1>
          <div className="flex justify-center text-md text-center px-20">
            <p className="text-md font-semibold text-gray-400">
              “At our autoparts company, our mission is to empower vehicle
              owners with high-quality, reliable parts at competitive prices
              while providing exceptional customer service to ensure every
              driver can keep their vehicles running smoothly and safely. We
              envision becoming the leading online destination for autoparts,
              where innovation meets convenience, creating a seamless shopping
              experience that connects customers with the best products.
              Together, we aim to foster a community of automotive enthusiasts
              and drive the future of automotive care, inspiring confidence on
              every journey.”
            </p>
          </div>
        </div>
        <img
          src={carImage}
          alt=""
          className="rounded-3xl animate__animated animate__fadeInRight hidden lg:flex"
        />
      </div>
      <div className="flex flex-col justify-center items-center text-center animate__animated animate__fadeIn mt-32">
        <h1 className="font-bold text-3xl text-secondary">
          WE BELIEVE IN. . .
        </h1>
        <p className="text-md font-semibold text-gray-400 lg:px-32 px-10 mb-6 mt-10">
          We believe in the power of quality and integrity, prioritizing
          reliable products that enhance vehicle performance and safety. Our
          commitment to customer satisfaction drives us to continually innovate
          and improve our offerings. We also value transparency and community,
          fostering connections among automotive enthusiasts to share knowledge
          and experiences. Together, we create a trusted space for all your
          autoparts needs.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
