import React, { useState, useEffect, useRef } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { TbMenu2 } from "react-icons/tb";
import { Link, useNavigate } from "react-router-dom";
import { FaRegCircleUser } from "react-icons/fa6";
import brandService from "../../Services/brandService";
import Cookies from "js-cookie";

const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [userDropDown, setUserDropDown] = useState(false)
  const [brands, setBrands] = useState([])
  const dropdownRef = useRef(null);
  const toggleDropdownRef = useRef(null);
  const isAuthenticated = Cookies.get("REJTLUNVU1RPTUVSLUFVVEhFTlRJQ0FUSU9OLVNFQ1JFVC1LRVk=")

  const navigate = useNavigate()

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const openDropdown = () => {
    setDropDown(!dropDown);
  }

  const navigation = useNavigate();

  useEffect(() => {
    const getBrands = async () => {
      try {
        const response = await brandService.fetchAllBrands()
        setBrands(response.brands)
      }
      catch {


      }
    }
    getBrands()
  }, [])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (toggleDropdownRef.current && !toggleDropdownRef.current.contains(event.target)) {
        setDropDown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toggleDropdownRef]);

  const handleLogout = () => {
    Cookies.remove("REJTLUNVU1RPTUVSLUFVVEhFTlRJQ0FUSU9OLVNFQ1JFVC1LRVk=");
    navigate('/login')
  }

  return (
    <header className="absolute flex w-full bg-transparent justify-between items-center px-6 py-4 z-50">
      <div className="flex flex-row items-center gap-10">

        <div ref={toggleDropdownRef} className="flex flex-row gap-3 items-center">
          <TbMenu2 onClick={openDropdown} className="bg-white md:hidden flex rounded-lg p-1 h-8 w-8 font-extrabold text-primary" />
          {dropDown && (
            <div className="absolute items-center flex flex-col px-4 space-y-4 py-2 bg-white text-primary mt-32 ml-8 rounded-lg shadow-lg z-30">
              <Link to={"/"}>Home</Link>
              <div ref={dropdownRef}>
                <button
                  className={`flex items-center gap-1`}
                  onClick={toggleDropdown}
                >
                  Car Brand
                  <MdOutlineKeyboardArrowDown
                    className={`duration-300 text-lg ${dropdownOpen === true ? "rotate-180" : ""
                      }`}
                  />
                </button>
                {dropdownOpen && (
                  <>
                    {brands.map((value, index) => (
                      <div className=" items-center flex flex-col px-4 space-y-1 py-1 bg-white text-primary mt-2 rounded-lg z-30" key={index}>
                        <h1 className="uppercase hover:bg-gray-100 rounded-lg px-3 hover:cursor-pointer text-xs">
                          {value.brand_name}
                        </h1>
                      </div>
                    ))}
                  </>
                )}

              </div>
              <Link to={"/about"}>About us</Link>
              <Link to={"/contact"}> Contact us</Link>
            </div>
          )}
          <h1 className="font-extrabold text-3xl text-secondary">DBS</h1>
        </div>
        <div className="gap-5 text-black font-semibold text-sm lg:text-base hidden md:flex">
          <Link to={"/"}>Home</Link>
          <div ref={dropdownRef}>
            <button
              className={`flex items-center gap-1`}
              onClick={toggleDropdown}
            >
              Car Brand
              <MdOutlineKeyboardArrowDown
                className={`duration-300 text-lg ${dropdownOpen === true ? "rotate-180" : ""
                  }`}
              />
            </button>
            {dropdownOpen && (
              <div className="absolute items-center flex flex-col px-4 space-y-2 py-2 bg-white text-primary mt-2 rounded-lg shadow-lg z-30">
                {brands.map(value =>
                  <h1 className="uppercase hover:bg-gray-100 rounded-lg px-3 py-1 hover:cursor-pointer text-sm">
                    {value.brand_name}
                  </h1>
                )}
              </div>
            )}
          </div>
          <Link to={"/about"}>About us</Link>
          <Link to={"/contact"}> Contact us</Link>
        </div>
      </div>
      {!isAuthenticated ?
        <div className="flex flex-row gap-10 items-center">
          <div className="flex gap-3">
            <button
              onClick={() => navigation("/login")}
              className="bg-primary text-white text-sm font-semibold px-3 py-[3px] rounded-lg hover:scale-105 hover:shadow-gray-300 shadow-lg hover:duration-300"
            >
              Login
            </button>
            <button
              onClick={() => navigation("/signup")}
              className="bg-primary text-white text-sm font-semibold px-3 py-2 rounded-lg hover:scale-105 hover:shadow-gray-300 shadow-lg hover:duration-300"
            >
              Sign Up
            </button>
          </div>
        </div> :
        <div className={`flex flex-row space-x-5`}>
          <p onClick={() => navigate('crm/details')} className={`cursor-pointer text-black font-light border-b-blue-700 border-[1px] hover:scale-110`}>My Account</p>
          <p onClick={() => handleLogout()} className={`cursor-pointer text-black font-light border-b-blue-700 border-[1px] hover:scale-110`}>Logout</p>

        </div>
      }

    </header>
  );
};

export default Navbar;
