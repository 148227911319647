import React, { useEffect, useState } from 'react';
import { AiOutlineFileExcel } from "react-icons/ai";
import { FaRegFileWord, FaRegFilePdf } from "react-icons/fa";
import { IoWallet } from "react-icons/io5";
import documentService from '../../Services/documentService';
import Loading from '../../Components/Loading';

const Documents = () => {
    const [loading, setLoading] = useState(false)
    const profile = JSON.parse(localStorage.getItem('customer'));
    const [documents, setDocuments] = useState([]);

    useEffect(() => {
        setLoading(true)
        documentService.fetchAllDocuments(profile.id).then(res => {
            setDocuments(res.documents);
            setLoading(false)
        });
    }, []);

    const getIconByFileType = (url) => {
        const extension = url.split('.').pop().toLowerCase();
        switch (extension) {
            case 'pdf':
                return <FaRegFilePdf size={50} color='black' />;
            case 'doc':
            case 'docx':
                return <FaRegFileWord size={50} color='black' />;
            case 'xls':
            case 'xlsx':
                return <AiOutlineFileExcel size={50} color='black' />;
            case 'jpg':
            case 'jpeg':
            case 'png':
                return <img src={url} alt="Document Image" className='max-w-full h-auto' />;
            default:
                return <IoWallet size={50} color='black' />;  // Default icon if the file type is unknown
        }
    };

    return (
        <div>
            <h1 className="flex justify-center text-center font-extrabold text-4xl text-primary mt-10">
                Documents
            </h1>
            <div className="flex flex-col bg-white gap-5 mx-10 mt-4 px-20 py-16 rounded-2xl">
                {
                    loading ? <Loading /> :
                        <>
                            {
                                documents.length > 0 ?
                                    <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5'>
                                        {documents.map((doc, index) => (
                                            <div key={index} className='flex flex-col p-8 bg-slate-200 items-center gap-2'>
                                                <div>{getIconByFileType(doc.url)}</div>
                                                <div>{doc.name}</div>
                                                <div>
                                                    <a href={doc.url} target="_blank" download className='bg-red-500 hover:bg-red-600 text-white py-1 px-3 rounded-xl'>
                                                        Download
                                                    </a>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    :
                                    <div className="text-gray-800 text-xl px-12 font-semibold">No Documents</div>

                            }
                        </>
                }
            </div>
        </div>
    );
};

export default Documents;