import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import stripeService from "../../Services/stripeService";
import toast from "react-hot-toast";
import customerService from "../../Services/customerService";
import { RiShoppingCartLine } from "react-icons/ri";
import { IoMdArrowDropdown } from "react-icons/io";
import 'animate.css'

const stripePromise = loadStripe(
  "pk_test_51NpsCXBA5mbdD8e2Tg8MVBCXtomGyF11MzP1eFRceziDGIOGxMwmjToCNFLQEc2zXeYnBUhk89oKcJ9ffXpSikqU00bObmoUIu"
);

const Checkout = () => {
  const [cart, setCart] = useState([]);
  const [isCartVisible, setIsCartVisible] = useState(false);

  const toggleCartVisibility = () => {
    setIsCartVisible((prevState) => !prevState);
  };

  const [data, setData] = useState({
    name: "",
    shipping_address: "",
    agent_address: "",
    country: "",
    phone_number: "",
    email: "",
  });

  const [errors, setErrors] = useState({
    name: false,
    shipping_address: false,
    agent_address: false,
    country: false,
    phone_number: false,
    email: false,
  });

  const [regex, setRegex] = useState({
    name: /.{1,50}$/,
    shipping_address: /.{1,50}$/,
    agent_address: /.{1,50}$/,
    country: /.{1,50}$/,
    phone_number: /^\+?[0-9]{7,15}$/,
    email: /^[\w\.\-]+@[a-zA-Z\d\-]+\.[a-zA-Z]{2,}$/,
  });

  const navigate = useNavigate();

  let payment = { success: false };
  const location = useLocation();
  const childRef = useRef(payment);

  useEffect(() => {
    if (location.state?.cart) {
      setCart(location.state?.cart);
    }
  }, []);

  const handleChange = (v, n) => {
    setData((d) => ({ ...d, [n]: v }));
    if (v.length > 0) {
      setErrors((prevState) => ({ ...prevState, [n]: false }));
    }
  };

  const handleSubmit = async () => {
    let hasError = false;
    Object.keys(data).map((v) => {
      if (!regex[v]?.test(data[v])) {
        console.log(v);
        setErrors((prevState) => ({ ...prevState, [v]: true }));
        hasError = true;
      }
    });

    if (hasError) {
      toast.error("Please fill the form correctly", { id: "1" });
    } else {
      const transformedCart = cart.map((item) => ({
        brand: item.brand,
        created_at: item.created_at,
        delivery: item.delivery,
        id: item.id,
        price: item.price,
        quantity: item.qty,
        show: item.qty,
        supplier_code: item.supplier_code,
        title: item.title,
        vendor_code: item.vendor_code,
        weight: item.weight,
        reference: "",
      }));
      let payload = {
        name: data.name,
        country: data.country,
        email: data.email,
        phone_number: data.phone_number,
        address: data.shipping_address,
        agent_address: data.agent_address,
        amount: totalAmount,
        products: transformedCart,
      };
      const response = await childRef.current.pay();
      if (response.success) {
        customerService.createTemporary(payload).then((res) => {
          toast.success("Order Placed Successfully", { id: "2" });
          navigate("/successPayment");
        });
      } else {
        toast.error("Unable to proceed payment", { id: "2" });
      }
    }
  };

  // const [quantities, setQuantities] = useState(
  //     data.reduce((acc, item) => ({ ...acc, [item.id]: 1 }), {})
  // );

  const totalAmount = cart.reduce(
    (total, item) => total + item.price * item.qty,
    0
  );

  return (
    <div className={`bg-gray-100`}>
      <h1 className={`pt-24 text-center font-extrabold text-3xl`}>
        Shopping Cart
      </h1>
      <div className="flex flex-col md:flex-row pt-16 text-black px-10 md:px-10 lg:px-[15%] py-12 bg-gray-100 w-full">
        <div className="lg:w-3/5 px-4">
          <div
            className="flex flex-row justify-between items-center w-full bg-secondary rounded-lg shadow-lg py-4 px-4"
            onClick={toggleCartVisibility}
          >
            <div className="flex gap-2 items-center">
              <RiShoppingCartLine className="text-white" />
              <h1 className="font-extrabold text-lg text-white">Cart</h1>
              <h1 className={`font-extrabold text-[10px] text-white bg-green-500 py-[1px] px-[6px] rounded-full`}>
                 {cart.length}
              </h1>
            </div>
            <div>
              <IoMdArrowDropdown className={`text-white duration-300 ${isCartVisible === true ? 'rotate-180' : ''}`} />
            </div>
          </div>
          {isCartVisible && (
            <div className="animate__animated animate__fadeIn">
              {cart.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="bg-white grid grid-cols-3 my-2 p-3 items-center rounded-md"
                  >
                    <div className="flex flex-col">
                      <p className="text-[10px] font-light">
                        {item.vendor_code}
                      </p>
                      <p className="text-[16px] font-semibold">{item.title}</p>
                    </div>
                    <div className={`flex justify-end`}>
                      <div className="text-gray-600 font-light text-[12px] text-end mr-10">
                        {item.price} AED
                      </div>
                      <div className="w-[50px] text-gray-600 font-light text-[12px] text-end">
                        {item.qty} Pcs
                      </div>
                    </div>
                    <div className="flex justify-end">
                      <h1 className="flex justify-center text-center font-extrabold text-green-700 text-lg">
                        {parseFloat(item.price * item.qty).toFixed(2)}
                      </h1>
                      <h1 className="ml-1 font-light text-gray-600 text-xs">
                        AED
                      </h1>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className="md:w-[50%] lg:w-2/5 rounded-3xl mt-10 md:mt-0 bg-primary text-white p-8 text-[16px] font-semibold ml-0 md:ml-4 lg:ml-12 py-10">
          <h2 className="text-[20px] font-bold mt-6">Contact Information</h2>
          <div className="grid grid-cols-2 gap-x-3 gap-y-1 px-[3%] mt-3">
            <div>
              <label
                className={`text-left tracking-wide text-grey-darker font-bold text-[9px] mb-[3px] uppercase`}
              >
                Name
              </label>
              <input
                value={data.name}
                name="name"
                onChange={(e) => handleChange(e.target.value, e.target.name)}
                // placeholder="Enter Your Name"
                className={` border border-gray-200 focus:outline-none focus:border-primary py-1 w-full ${
                  errors["name"] ? "border-red-600" : "border-gray-300"
                } px-3 bg-white bg-opacity-20 rounded-lg p-0.5`}
              />
            </div>
            <div>
              <label
                className={`text-left tracking-wide text-grey-darker font-bold text-[9px] mb-[3px] uppercase`}
              >
                Phone Number
              </label>
              <input
                value={data.phone_number}
                name="phone_number"
                onChange={(e) => handleChange(e.target.value, e.target.name)}
                // placeholder="Phone Number"
                className={`py-1 border border-gray-200 focus:outline-none focus:border-primary  w-full ${
                  errors["phone_number"] ? "border-red-600" : "border-gray-300"
                } px-3 bg-white bg-opacity-20 rounded-lg p-0.5`}
              />
            </div>
            <div>
              <label
                className={`text-left tracking-wide text-grey-darker font-bold text-[9px] mb-[3px] uppercase`}
              >
                Email
              </label>
              <input
                value={data.email}
                name="email"
                onChange={(e) => handleChange(e.target.value, e.target.name)}
                // placeholder="Email"
                className={`py-1 border border-gray-200 focus:outline-none focus:border-primary w-full ${
                  errors["email"] ? "border-red-600" : "border-gray-300"
                } px-3 bg-white bg-opacity-20 rounded-lg p-0.5`}
              />
            </div>
          </div>
          <h2 className="text-[20px] font-bold mt-6">Shipping Information</h2>
          <div className="grid grid-cols-1 gap-x-3 gap-y-2 px-[3%] mt-3">
            <div>
              <label
                className={`text-left tracking-wide text-grey-darker font-bold text-[9px] mb-[3px] uppercase`}
              >
                Shipping Address
              </label>
              <input
                value={data.shipping_address}
                name="shipping_address"
                onChange={(e) => handleChange(e.target.value, e.target.name)}
                // placeholder="Shipping Address"
                className={`py-1 border border-gray-200 focus:outline-none focus:border-primary  w-full ${
                  errors["shipping_address"]
                    ? "border-red-600"
                    : "border-gray-300"
                } px-3 bg-white bg-opacity-20 rounded-lg p-0.5`}
              />
            </div>
            <div>
              <label
                className={`text-left tracking-wide text-grey-darker font-bold text-[9px] mb-[3px] uppercase`}
              >
                Agent Address
              </label>
              <input
                value={data.agent_address}
                name="agent_address"
                onChange={(e) => handleChange(e.target.value, e.target.name)}
                // placeholder="Agent Address"
                className={`py-1 border border-gray-200 focus:outline-none focus:border-primary  w-full ${
                  errors["agent_address"] ? "border-red-600" : "border-gray-300"
                } px-3 bg-white bg-opacity-20 rounded-lg p-0.5`}
              />
            </div>
            <div>
              <label
                className={`text-left tracking-wide text-grey-darker font-bold text-[9px] mb-[3px] uppercase`}
              >
                Country
              </label>
              <input
                value={data.country}
                name="country"
                onChange={(e) => handleChange(e.target.value, e.target.name)}
                // placeholder="Country"
                className={`py-1 border border-gray-200 focus:outline-none focus:border-primary  w-full ${
                  errors["country"] ? "border-red-600" : "border-gray-300"
                } px-3 bg-white bg-opacity-20 rounded-lg p-0.5`}
              />
            </div>
          </div>
          <h2 className="text-[20px] font-bold mt-6">Payment Details</h2>
          <div className="grid grid-cols-1 gap-3 px-[3%] mt-2">
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret:
                  "pi_3Q6lazBA5mbdD8e206s1wPwC_secret_ZOzBMzHBHfVaf9jGEaCrODDSJ",
              }}
            >
              <Payment
                amount={parseFloat(totalAmount).toFixed(2)}
                ref={childRef}
              />
            </Elements>
          </div>

          <div className="mt-16 mx-8 flex flex-col space-y-2">
            <div className="flex justify-between">
              <div className={`text-sm font-bold`}>Sub Total</div>
              <div className="flex justify-center ">
                <h1 className="flex justify-center text-center font-extrabold text-white text-xl">
                  {parseFloat(totalAmount).toFixed(2)}
                </h1>
                <h1 className="ml-1 font-light text-gray-300 text-[10px]">
                  AED
                </h1>
              </div>
            </div>
            <div className="flex justify-between">
              <div className={`text-sm`}>Shipping</div>
              <div className="flex justify-center ">
                <h1 className="flex justify-center text-center font-extrabold text-white text-xl">
                  0
                </h1>
                <h1 className="ml-1 font-light text-gray-300 text-[10px]">
                  AED
                </h1>
              </div>
            </div>
            <hr />
            <div className="flex justify-between items-center">
              <div className={`font-regular text-[18px]`}>Total</div>
              <div className="flex justify-center ">
                <h1 className="flex justify-center text-center font-extrabold text-white text-4xl">
                  {parseFloat(totalAmount).toFixed(2)}
                </h1>
                <h1 className="ml-1 font-light text-gray-300 text-sm">AED</h1>
              </div>
            </div>
          </div>

          <button
            onClick={() => handleSubmit()}
            className="bg-[#4DE1C1] text-white w-full py-2 text-[20px] font-bold rounded-xl mt-4"
          >
            Pay
          </button>
        </div>
      </div>
    </div>
  );
};

const Payment = forwardRef(({ amount }, ref) => {
  const stripe = useStripe();
  const elements = useElements();

  useImperativeHandle(ref, () => ({
    async pay() {
      let price = parseInt(amount.toString().replaceAll(".", ""));
      return await stripeService
        .createIntent({ price: price })
        .then(async (response) => {
          if (!stripe || !elements) {
            return { success: false };
          }

          const cardElement = elements.getElement(CardElement);

          const { error, paymentIntent } = await stripe.confirmCardPayment(
            response.clientSecret,
            {
              payment_method: {
                card: cardElement,
              },
            }
          );

          if (error) {
            return { success: false };
          } else if (paymentIntent && paymentIntent.status === "succeeded") {
            return { success: true };
          }
        });
    },
  }));
  // const handleSubmit = async () => {
  //
  // }

  return (
    <CardElement
      options={{
        style: {
          base: {
            fontWeight: "700",
            fontFamily: "inherit",
            color: "#ffffff",
            "::placeholder": {
              color: "#afafaf",
              fontWeight: "500",
            },
          },
        },
        hidePostalCode: true,
      }}
      className={`py-3 border border-gray-200 focus:outline-none focus:border-primary  w-full px-3 bg-white bg-opacity-20 rounded-lg p-0.5`}
    />
  );
});

export default Checkout;
