import React from "react";
import { FiSend } from "react-icons/fi";

const ContactUs = () => {
    return (
        <div className="bg-gray-100 lg:min-h-screen py-20 lg:py-0 flex items-center justify-center">
            <div className="max-w-5xl w-full bg-white rounded-lg p-6 md:p-16 lg:mt-20">
                <h1 className="font-extrabold text-primary text-4xl text-center">Contact Us</h1>
                <div className="flex flex-col md:flex-row justify-between mt-16">
                    {/* Left Side */}
                    <div className="mb-8 md:mb-0 md:w-1/2">
                        <h2 className="text-3xl text-gray-800 font-bold mb-2">Let's talk</h2>
                        <p className="text-md text-gray-600 mb-6 ">Not sure what you need? The team of DBS Spare Parts will be happy to listen to you and We will suggest you auto parts as per your requirement.</p>
                        <p className="flex items-center mb-2">
                            <span className="mr-3 font-bold text-sm">
                                Phone :
                            </span>
                            <span>+971 54 401 4523</span>
                        </p>
                        <p className="flex items-center">
                            <span className="mr-3 font-bold text-sm">
                                Email :
                            </span>
                            <span>info@dbsspareparts.com</span>
                        </p>
                    </div>

                    {/* Right Side Form */}
                    <div className="md:w-1/2">
                        <form className="text-gray-100 space-y-6">
                            <div className="flex space-x-4">
                                <input
                                    type="text"
                                    placeholder="Name"
                                    className="w-1/2 p-3 bg-gray-50 border border-gray-200 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-600 text-black"
                                />
                                <input
                                    type="email"
                                    placeholder="Email"
                                    className="w-1/2 p-3 bg-gray-50 border border-gray-200 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-600 text-black"
                                />
                            </div>
                            <textarea
                                placeholder="Message"
                                className="w-full p-3 bg-gray-50 border border-gray-200 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-600 h-32 text-black"
                            ></textarea>
                            <button className="flex items-center text-white mx-auto justify-center bg-blue-500 hover:bg-blue-600 transition-colors duration-200 p-3 rounded-lg shadow-lg w-24">
                                <span className="mr-2">Send</span>
                                <FiSend />
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
