import React, { useEffect, useState } from 'react'
import { MdEdit } from "react-icons/md";
import { CgDetailsMore } from "react-icons/cg";
import { FaRegEdit } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import toast from 'react-hot-toast';
import customerService from '../../Services/customerService';
import Loading from '../../Components/Loading';

const CompanyProfile = () => {
    const [loading, setLoading] = useState(false)

    const [tab, setTab] = useState('details')
    const [profile, setProfile] = useState({})
    const [editCompany, setEditCompany] = useState(false)
    const customerProfile = JSON.parse(localStorage.getItem('customer'))
    const [companyData, setCompanyData] = useState({})
    const [companyTrigger, setCompanyTrigger] = useState(false)

    const [errors, setErrors] = useState({
        phone_number: false,
        office_address: false,
        warehouse_address: false
    })

    const [companyRegex, setCompanyRegex] = useState({
        phone_number: /^\+?[0-9]{7,15}$/,
        office_address: /.{3,50}$/,
        warehouse_address: /.{3,50}$/,
    })

    const handleCompanyChange = (v, n) => {
        setCompanyData(d => ({ ...d, [n]: v }))
        if (v.length > 0) {
            setErrors(prevState => ({ ...prevState, [n]: false }))
        }
    }

    useEffect(() => {
        setLoading(true)
        customerService.fetchSingle(customerProfile.email).then(res => {
            setCompanyData(res.customer)
            setLoading(false)
        })
    }, [companyTrigger])

    const handleCompanyUpdate = () => {
        let hasError = false;
        Object.keys(companyRegex).map(v => {
            if (!companyRegex[v]?.test(companyData[v])) {
                console.log(v)
                setErrors(prevState => ({ ...prevState, [v]: true }))
                hasError = true
            }
        })
        console.log(hasError)

        if (hasError) {
            toast.error('Please fill the form correctly', { id: "1" });
        } else {
            console.log(customerProfile.id)
            console.log(companyData)
            customerService.updateCompanyProfile(customerProfile.id, companyData).then(res => {
                toast.success('Update Success', { id: "2" });
                setEditCompany(false)
                setCompanyTrigger(!companyTrigger)
            })
        }
    }

    const [editRepresentative, setEditRepresentative] = useState(false)
    const [representativeData, setRepresentativeData] = useState({
        rep_email: '',
        rep_phone: ""
    })
    const [representativeTrigger, setRepresentativeTrigger] = useState(false)

    const [representativeErrors, setRepresentativeErrors] = useState({
        rep_email: false,
        rep_phone: false
    })

    const [representativeRegex, setRepresentativeRegex] = useState({
        rep_email: /^[\w\.\-]+@[a-zA-Z\d\-]+\.[a-zA-Z]{2,}$/,
        rep_phone: /^\+?[0-9]{7,15}$/

    })

    useEffect(() => {
        customerService.fetchSingle(customerProfile.email).then(res => {
            setRepresentativeData(res.customer)
        })
    }, [representativeTrigger])

    const handleRepresentativeChange = (v, n) => {
        setRepresentativeData(d => ({ ...d, [n]: v }))
        if (v.length > 0) {
            setRepresentativeErrors(prevState => ({ ...prevState, [n]: false }))
        }
    }

    const handleRepresentativeUpdate = () => {
        let hasError = false;
        Object.keys(representativeRegex).map(v => {
            if (!representativeRegex[v]?.test(representativeData[v])) {
                setRepresentativeErrors(prevState => ({ ...prevState, [v]: true }))
                hasError = true
            }
        })
        console.log(hasError)

        if (hasError) {
            toast.error('Please fill the form correctly', { id: "1" });
        } else {
            console.log(customerProfile.id)
            console.log(representativeData)

            customerService.updateProfile(customerProfile.id, representativeData).then(res => {
                toast.success('Update Success', { id: "2" });
                setEditRepresentative(false)
                setRepresentativeTrigger(!representativeTrigger)
            })
        }
    }


    const [credentialsData, setCredentialsData] = useState({
        password: '',
        confirmed_password: ""
    })

    const [credentialErrors, setCredentialErrors] = useState({
        password: false,
        confirmed_password: false
    })

    const [credentialRegex, setCredentialRegex] = useState({
        password: /.{3,50}$/,
        confirmed_password: /.{3,50}$/
    })

    const handleCredentialsChange = (v, n) => {
        setCredentialsData(d => ({ ...d, [n]: v }))
        if (v.length > 0) {
            setCredentialErrors(prevState => ({ ...prevState, [n]: false }))
        }
    }

    const handleCredentialsUpdate = () => {
        let hasError = false;
        Object.keys(credentialsData).map(v => {
            if (!credentialRegex[v]?.test(credentialsData[v])) {
                setCredentialErrors(prevState => ({ ...prevState, [v]: true }))
                hasError = true
            }
        })

        if (hasError) {
            toast.error('Password must be at least 3 char', { id: "1" });
        } else if (credentialsData.password !== credentialsData.confirmed_password) {
            toast.error('Passwords donot Match', { id: "4" })
            setCredentialErrors(prevState => ({ ...prevState, password: true, confirmed_password: true }))
            return;
        } else {
            customerService.updatePassword(customerProfile.email, credentialsData).then(res => {
                toast.success('Success Password Changed', { id: "3" })
                setCredentialsData({
                    password: "",
                    confirmed_password: ""
                })
            })

        }
    }
    return (
        <div>
            <h1 className="flex justify-center text-center font-extrabold text-4xl text-primary mt-10">Company Profile</h1>
            <div className='m-10 mt-8 md:px-[10%] bg-white py-10 shadow-lg rounded-xl'>
                <h3 className='text-xl font-semibold'>Spare Auto Parts</h3>
                <p className='text-slate-800'>Autoparts</p>
                <div className='flex gap-4 mt-6'>
                    <div className={`flex items-center border-b-4  hover:cursor-pointer ${tab === 'details' ? 'border-slate-600 ' : 'border-transparent'} py-0.5 text-slate-600`}
                        onClick={() => setTab('details')}
                    >
                        <CgDetailsMore className='h-5 w-5 mr-2' />
                        Profile Details</div>
                    <div className={`flex items-center border-b-4 hover:cursor-pointer ${tab === 'representative' ? 'border-slate-600 ' : 'border-transparent'} py-0.5 text-slate-600`}
                        onClick={() => setTab('representative')}
                    >
                        <FaUser className='h-5 w-5 mr-2' />
                        Representative Profile</div>
                    <div className={`flex items-center border-b-4 hover:cursor-pointer ${tab === 'settings' ? 'border-slate-600 ' : 'border-transparent'} py-0.5 text-slate-600`}
                        onClick={() => setTab('settings')}
                    >
                        <MdEdit className='h-5 w-5 mr-2' />
                        Change Password</div>
                </div>

                {
                    loading ? (
                        <div className='flex justify-center items-center h-full'>
                            <p className='mt-28'><Loading /></p> {/* You can replace this with a spinner component if you have one */}
                        </div>
                    ) : (
                        tab === 'details' && (
                            <div className='mt-3 text-slate-700'>
                                <div className='text-[20px] text-black font-semibold'>Company Information</div>
                                <div className='grid grid-cols-4 items-center py-1'>
                                    <h3 className='text-[18px] font-semibold'>License Info</h3>
                                    <div className='ml-8 p-2 col-span-3 text-slate-600'>
                                        {companyData.license && (
                                            companyData.license.endsWith('.pdf') ? (
                                                <a href={companyData.license} target='_blank' rel='noopener noreferrer' className='text-primary font-semibold'>
                                                    View License PDF
                                                </a>
                                            ) : (
                                                <a href={companyData.license} target='_blank' rel='noopener noreferrer' className='text-primary font-semibold'>
                                                    View License Image
                                                </a>
                                            )
                                        )}

                                    </div>
                                </div>

                                <div className='grid grid-cols-4 items-center py-1'>
                                    <h3 className='text-[18px] font-semibold'>Email</h3>
                                    <div className='ml-8 p-2 col-span-3 text-slate-600'>{companyData.email}</div>
                                </div>
                                <div className='grid grid-cols-4 items-center py-1'>
                                    <h3 className='text-[18px] font-semibold'>Phone Number</h3>
                                    {
                                        editCompany ? (
                                            <input type='text'
                                                name='phone_number'
                                                value={companyData.phone_number}
                                                onChange={(e) => handleCompanyChange(e.target.value, e.target.name)}
                                                className={`ml-8 p-2 col-span-1 text-slate-600 ${errors['phone_number'] ? 'border border-red-600' : ''}`}
                                            />
                                        ) : (
                                            <div className='ml-8 p-2 col-span-3 text-slate-600'>
                                                {companyData.phone_number}
                                            </div>
                                        )
                                    }
                                </div>
                                <div className='grid grid-cols-4 items-center py-1'>
                                    <h3 className='text-[18px] font-semibold'>Office Address</h3>
                                    {
                                        editCompany ? (
                                            <textarea
                                                name='office_address'
                                                value={companyData.office_address}
                                                rows={3}
                                                onChange={(e) => handleCompanyChange(e.target.value, e.target.name)}
                                                className={`ml-8 p-2 col-span-3 text-slate-600 ${errors['office_address'] ? 'border border-red-600' : ''}`}
                                            />
                                        ) : (
                                            <div className='ml-8 p-2 col-span-3 text-slate-600'>
                                                {companyData.office_address}
                                            </div>
                                        )
                                    }
                                </div>
                                <div className='grid grid-cols-4 items-center py-1'>
                                    <h3 className='text-[18px] font-semibold'>Warehouse Address</h3>
                                    {
                                        editCompany ? (
                                            <textarea
                                                name='warehouse_address'
                                                value={companyData.warehouse_address}
                                                rows={3}
                                                onChange={(e) => handleCompanyChange(e.target.value, e.target.name)}
                                                className={`ml-8 p-2 col-span-3 text-slate-600 ${errors['warehouse_address'] ? 'border border-red-600' : ''}`}
                                            />
                                        ) : (
                                            <div className='ml-8 p-2 col-span-3 text-slate-600'>
                                                {companyData.warehouse_address}
                                            </div>
                                        )
                                    }
                                </div>
                                {
                                    !editCompany ? (
                                        <button className='flex items-center px-3 py-2 rounded-md bg-primary text-white'
                                            onClick={() => setEditCompany(true)}
                                        >
                                            <MdEdit className='w-5 h-5 mr-2' />
                                            Edit Details
                                        </button>
                                    ) : (
                                        <button className='flex items-center px-3 py-2 rounded-md bg-primary text-white'
                                            onClick={() => handleCompanyUpdate()}
                                        >
                                            Update
                                        </button>
                                    )
                                }
                            </div>
                        )
                    )
                }

                {
                    tab === "settings" ?
                        <div className='mt-3'>
                            <div className="grid grid-cols-1 w-[30%]">
                                <div>
                                    <label
                                        className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                                        Password
                                    </label>
                                    <input
                                        name="password"
                                        type='password'
                                        value={credentialsData.password}
                                        onChange={(e) => handleCredentialsChange(e.target.value, e.target.name)}
                                        className={`border border-gray-200 rounded-md p-1 px-2 focus:outline-none focus:border-primary  w-full ${credentialErrors['password'] ? "border-red-600" : "border-gray-300"}`}
                                    />
                                </div>
                                <div>
                                    <label
                                        className={`text-left tracking-wide text-grey-darker text-[0.8rem] font-[600] mb-[3px]`}>
                                        Confirm Password
                                    </label>
                                    <input
                                        // value={data.last_name}
                                        name="confirmed_password"
                                        type='password'
                                        value={credentialsData.confirmed_password}
                                        onChange={(e) => handleCredentialsChange(e.target.value, e.target.name)}
                                        className={`border border-gray-200 rounded-md p-1 px-2 focus:outline-none focus:border-primary  w-full ${credentialErrors['confirmed_password'] ? "border-red-600" : "border-gray-300"}`}
                                    />
                                </div>
                            </div>
                            <div className='flex mt-4'>
                                <button className='bg-primary text-white rounded-md py-2 px-3'
                                    onClick={() => handleCredentialsUpdate()}
                                >Change Password</button>
                            </div>
                        </div> : ""
                }
                {
                    tab === "representative" ?
                        <div className='mt-3 text-slate-700'>
                            <div className='text-[20px] text-black font-semibold'>Representative Profile</div>
                            <div className='grid grid-cols-4 items-center py-1'>
                                <h3 className='text-[18px] font-semibold'>Phone</h3>
                                {
                                    editRepresentative ?
                                        <input
                                            name='rep_phone'
                                            value={representativeData.rep_phone}
                                            onChange={(e) => handleRepresentativeChange(e.target.value, e.target.name)}
                                            className={`ml-8 p-2 col-span-3 text-slate-600' ${representativeErrors['rep_phone'] ? 'border border-red-600' : ""}`}
                                        />
                                        :
                                        <div className='ml-8 p-2 col-span-3 text-slate-600'>
                                            {representativeData.rep_phone}
                                        </div>
                                }
                            </div>
                            <div className='grid grid-cols-4 items-center py-1'>
                                <h3 className='text-[18px] font-semibold'>Email</h3>
                                {
                                    editRepresentative ?
                                        <input
                                            name='rep_email'
                                            value={representativeData.rep_email}
                                            onChange={(e) => handleRepresentativeChange(e.target.value, e.target.name)}
                                            className={`ml-8 p-2 col-span-3 text-slate-600' ${representativeErrors['rep_email'] ? 'border border-red-600' : ""}`}
                                        />
                                        :
                                        <div className='ml-8 p-2 col-span-3 text-slate-600'>
                                            {representativeData.rep_email}
                                        </div>
                                }
                            </div>
                            {
                                !editRepresentative ?
                                    <button className='flex items-center px-3 py-2 rounded-md bg-primary text-white mt-6 md:mt-0'
                                        onClick={() => setEditRepresentative(true)}
                                    >
                                        <MdEdit className='w-5 h-5 mr-2' />
                                        Edit Representative
                                    </button>
                                    :
                                    <button className='flex items-center px-3 py-2 rounded-md bg-primary text-white mt-6 md:mt-0'
                                        onClick={() => handleRepresentativeUpdate()}
                                    >
                                        Update Rep.
                                    </button>
                            }
                        </div> : ""
                }
            </div>
        </div>
    )
}

export default CompanyProfile