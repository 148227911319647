import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import orderService from '../../Services/orderService';
import moment from "moment";

const DeliveryDate = ({ isOpen, onClose, order, trigger, setTrigger }) => {
    const [errors, setErrors] = useState({});
    const [delivery, setDelivery] = useState(dayjs())

    const handleDateChange = (newDate) => {
        setDelivery(newDate)
        if (newDate) {
            setErrors((prevState) => ({ ...prevState, delivery_date: false }));
        }
    };

    const handleSubmit = (id) => {
        if (!delivery || !delivery.isValid()) {
            setErrors((prevErrors) => ({ ...prevErrors, delivery_date: true }));
            return;
        }
        else {
            orderService.updateDeliveryDate(order.id, { delivery_date: moment(delivery["$d"]).format("YYYY-MM-DD") }).then(res => {
                setTrigger(!trigger)
                toast.success("Delivery Date Confirmed");
            })
        }
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-gray-100 p-6 rounded-md shadow-lg w-[380px] h-[500px]">
                <div className="text-xl font-semibold  text-center  ">Please Select a Delivery Date</div>

                <div className=' flex items-center justify-center'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateCalendar']}>
                            <DemoItem>
                                <DateCalendar value={delivery} onChange={handleDateChange} />
                            </DemoItem>
                        </DemoContainer>
                    </LocalizationProvider>
                    {errors['delivery_date'] && (
                        <span className="text-red-500 text-sm"> Select a delivery date </span>
                    )}
                </div>

                <div className="flex justify-end space-x-3 mr-8">
                    <button
                        className="px-3 py-1 bg-red-600 text-white rounded-md"
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <button
                        className="px-3 py-1 bg-blue-600 text-white rounded-md"
                        onClick={() => handleSubmit()}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DeliveryDate;
