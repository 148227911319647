import axiosInstance from "./axiosInstance";

const stripeService = {
    createIntent: async (payload) => {
        try {
            const response = await axiosInstance.post('/stripe/createIntent', payload);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
}

export default stripeService
