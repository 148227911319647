import axiosInstance from "./axiosInstance";

const walletService = {
    fetchAmount: async (id) => {
        try {
            const response = await axiosInstance.get('/wallets/single/' + id);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    chargeAmount: async (id, payload) => {
        try {
            const response = await axiosInstance.post('/wallets/charge/' + id, payload);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    deductAmount: async (id, payload) => {
        try {
            const response = await axiosInstance.post('/wallets/deduct/' + id, payload);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
}

export default walletService;
