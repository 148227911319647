import axiosInstance from "./axiosInstance";

const orderService = {
    fetchAll: async () => {
        try {
            const response = await axiosInstance.get('/orders/all');
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    fetchAllDetails: async () => {
        try {
            const response = await axiosInstance.get('/orders/allDetails');
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    fetchTotal: async () => {
        try {
            const response = await axiosInstance.get('/orders/total');
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    fetchByTrackingNumber: async (tracking) => {
        try {
            const response = await axiosInstance.get('/orders/track/' + tracking);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    fetchByInvoice: async (invoice) => {
        try {
            const response = await axiosInstance.get('/orders/single/' + invoice);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    fetchCustomerUnpaid: async (id) => {
        try {
            const response = await axiosInstance.get('/orders/customerUnpaid/' + id);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    fetchCustomerTotal: async (id) => {
        try {
            const response = await axiosInstance.get('/orders/customerTotal/' + id);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    fetchCustomerBalance: async (id) => {
        try {
            const response = await axiosInstance.get('/orders/customerBalance/' + id);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    fetchCustomerPaid: async (id) => {
        try {
            const response = await axiosInstance.get('/orders/customerPaid/' + id);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    fetchConfirmedOrders: async (id) => {
        try {
            const response = await axiosInstance.get('/orders/confirmedOrders/' + id);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    fetchDeliveredOrders: async (id) => {
        try {
            const response = await axiosInstance.get('/orders/deliveredOrders/' + id);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    fetchPendingOrders: async (id) => {
        try {
            const response = await axiosInstance.get('/orders/pendingOrders/' + id);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    fetchReadyToDeliverOrders: async (id) => {
        try {
            const response = await axiosInstance.get('/orders/readyToDeliverOrders/' + id);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    fetchUnderProcessOrders: async (id) => {
        try {
            const response = await axiosInstance.get('/orders/underProcessOrders/' + id);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    fetchDispatchedOrders: async (id) => {
        try {
            const response = await axiosInstance.get('/orders/dispatchedOrders/' + id);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    submitOrder: async (payload) => {
        try {
            const response = await axiosInstance.post('/orders/submitOrder', payload);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    updateDeliveryDate: async (id, payload) => {
        try {
            const response = await axiosInstance.put('/orders/deliveryDate/' + id, payload);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    updateOrderAmount: async (tracking, payload) => {
        try {
            const response = await axiosInstance.put('/orders/orderAmount/' + tracking, payload);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    confirmOrder: async (id) => {
        try {
            const response = await axiosInstance.put('/orders/confirmOrder/' + id);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    makePayment: async (id) => {
        try {
            const response = await axiosInstance.put('/orders/makePayment/' + id);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    updateStatus: async (id, payload) => {
        try {
            const response = await axiosInstance.post('/orders/status/' + id, payload);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
    deleteOrder: async (id) => {
        try {
            const response = await axiosInstance.delete('/orders/delete/' + id);
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
}

export default orderService;
