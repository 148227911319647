import React, { useEffect, useRef, useState } from "react";
import { FiUsers } from "react-icons/fi";
import { RiEBike2Line } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { IoDocumentsOutline } from "react-icons/io5";
import profile from "../../assests/profile.png"
// Adjust the path as necessary
import { useLocation, useNavigate } from "react-router-dom";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const menuItems = [
    { name: "Profile", icon: <CgProfile />, path: "/crm/details" },
    { name: "Orders", icon: <RiEBike2Line />, path: "/crm/orders" },
    { name: "Accounts", icon: <FiUsers />, path: "/crm/accounts" },
    { name: "Documents", icon: <IoDocumentsOutline />, path: "/crm/documents" },
  ];

  const customerProfile = JSON.parse(localStorage.getItem('customer'))
  const navigate = useNavigate();
  const location = useLocation();
  const sidebarRef = useRef(null);


  // Handle click outside the sidebar
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        toggleSidebar();
      }
    };

    // Attach event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toggleSidebar]);

  return (
    <div
      ref={sidebarRef}
      className={`fixed inset-y-0 z-50 left-0 bg-primary text-white w-64 transform transition-transform duration-300 
            ${isOpen ? "translate-x-0" : "-translate-x-full"
        } lg:translate-x-0 lg:static lg:w-64`}
    >
      <div className="flex flex-col items-center mt-8 text-white">
        <img src={profile} alt="Profile" className="rounded-full h-28 w-28" />
        <div className="flex flex-col items-center bg-white w-[200px] rounded-md py-2 mt-5">
          <div className="font-extrabold text-md text-primary">
            {customerProfile?.first_name}  {customerProfile?.last_name}
          </div>
          <div className="text-sm text-primary -mt-1">Customer</div>
        </div>
      </div>
      <div className="py-5">
        {menuItems.map((item, index) => (
          <div key={index} className='text-black'
            onClick={() => {
              if (!item.sub) {
                if (location.pathname !== item.path) {
                  navigate(item.path);
                }
              } else {
                setExpandedIndex(expandedIndex === index ? null : index);
              }
            }}>
            <div
              className={`cursor-pointer flex flex-col items-center border-l-4 border-primary py-5
                             ${location.pathname.includes(item.path)
                  ? " bg-blue-600 font-normal border-l-4 border-white text-slate-200 "
                  : " hover:bg-blue-600 hover:border-l-4 hover:border-white text-slate-200"
                }`}>
              <div className={` ${location.pathname.includes(item.path) ? "rounded-full p-2" : "p-2"}`}>
                {React.cloneElement(item.icon, { className: ' h-8 w-8' })}
              </div>
              <div className=' text-1xl '>
                {item.name}
              </div>
            </div>
            {expandedIndex === index && item.items && (
              <div className="flex flex-col ml-8">
                {item.items.map((subItem, subIndex) => (
                  <div key={subIndex}
                    className={`py-[8px] cursor-pointer flex items-center pl-6
                                     ${location.pathname === subItem.path
                        ? "bg-[#F6E0E2] text-[#FD8562] rounded-full border-l-4 border-r-4 border-[#FF6E40] font-semibold"
                        : "bg-[#F5F4FE] hover:text-[#F46C64] hover:cursor-pointer border-l-4 border-r-4 border-transparent"
                      }`}
                    onClick={() => {
                      if (location.pathname !== subItem.path) {
                        navigate(subItem.path);
                      }
                    }}>
                    <div
                      className={`mr-3 ${location.pathname === subItem.path ? "bg-white rounded-full p-2" : "p-2"}`}>
                      {React.cloneElement(subItem.icon, { className: ' h-4 w-4' })}
                    </div>
                    <div className=' text-1xl '>
                      {subItem.name}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
