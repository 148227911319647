import { Box } from "@mui/material";
import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import UnderProcess from '../Components/UnderProcess'
import Confirmed from '../Components/Confirmed'
import Purchased from '../Components/Purchased'
import NotAvailable from '../Components/NotAvailable'
import InTransit from '../Components/InTransit'
import InStock from '../Components/InStock'
import Packing from '../Components/Packing'
import Shipment from '../Components/Shipment'
import Delivered from '../Components/Delivered'
import { useNavigate } from "react-router-dom";
import WaitingConfirmation from "../Components/WaitingConfirmation";


const prod = [
  {
    brand: "Toyota",
    vendor: "12345",
    title: "Pump",
    available: "13",
    weight: "10 kg",
    t_weight: "20",
    days: "10",
    price: "127.5",
    t_price: "250",
  },
  // Add more products as needed
];

const OrderDetail = () => {
  const [tab, setTab] = useState("Pending");

  const navigate = useNavigate()

  const handleChange = (v) => {
    setTab(v);
  };

  return (
    <div className="md:m-10 m-4">
      <div>
        <h1 className="flex justify-center text-center font-extrabold text-4xl text-primary mt-10">
          Orders
        </h1>
      </div>
      <div className="flex justify-end mt-5">
        <button className="flex justify-end gap-2 bg-secondary text-white font-bold py-2 px-4 items-center rounded-lg"
          onClick={() => navigate('/crm/orders/add')}
        >
          <span className="font-light">
            <FaPlus className="font-light text-white" />
          </span>{" "}
          Add New Order
        </button>
      </div>
      <div className="flex flex-col gap-4 mt-10">
        <div className=" w-[100%] p-4 bg-white text-xs rounded-lg">
          <div className={`flex flex-row justify-center gap-16`}>
            {["Pending", "Confirmed", "Under Process", "Ready For Shipment", "In Transit", "Delivered"].map((v) => (
              <button
                key={v}
                onClick={() => handleChange(v)}
                className={`font-bold text-[8px] md:text-sm lg:text-base py-2 ${tab === v
                  ? "text-primary border-b-2 border-primary"
                  : "text-black"
                  }`}
              >
                {v}
              </button>
            ))}
          </div>
        </div>
        <p className={`text-center font-light text-black py-2`}>{tab === "Pending" &&
          "Pending Orders awaiting payment approval for final confirmation. Make sure uploading 30% payment for confirmation"
        }
        </p>
        <div className="flex flex-col w-[100%] text-xs bg-white rounded-lg">
          <Box sx={{ bgcolor: "background.paper" }} className="rounded-3xl">
            {tab === "Under Process" && (
              <div><UnderProcess /></div>
            )}
            {tab === "Confirmed" && (
              <div><Confirmed /></div>
            )}
            {tab === "Purchased" && (
              <div><Purchased /></div>
            )}
            {tab === "Pending" && (
              <div><WaitingConfirmation /> </div>
            )}
            {tab === "In Transit" && (
              <div><InTransit /></div>
            )}
            {tab === "In Stock" && (
              <div><InStock /></div>
            )}
            {tab === "In Packing" && (
              <div><Packing /></div>
            )}
            {tab === "Ready For Shipment" && (
              <div><Shipment /></div>
            )}
            {tab === "Delivered" && (
              <div><Delivered /></div>
            )}
          </Box>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
