import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import * as xlsx from "xlsx";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import excel_file from '../assests/dbs_quotation_sample.xlsx'

export default function FullWidthTabs() {

    const [tab, setTab] = React.useState("Part Number");
    const [products, setProducts] = useState([])
    const [file, setFile] = useState(null)
    const [partNumber, setPartNumber] = useState(null)

    const navigate = useNavigate()

    useEffect(() => {
        if (products.length !== 0) {
            navigate("/createOrder", { state: { products: products } })
        }
    }, [products])

    const handleFileSelect = (e) => {
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader()
            reader.onload = (e) => {
                const data = e.target.result;
                setFile(data)
            }
            reader.readAsArrayBuffer(e.target.files[0])
        }
    }

    const ProceedFileRead = () => {
        if (file) {
            const workbook = xlsx.read(file, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = xlsx.utils.sheet_to_json(worksheet);
            setProducts(json)
        } else {
            toast.error("Please upload any file!")
        }
    }

    const handleChange = (v) => {
        setTab(v);
    };

    const handleInput = (value, tab) => {
        if (tab === "Part Number") {
            setPartNumber(value)
        }
    }

    const handleSearch = (tab) => {
        if (tab === "Part Number") {
            if (partNumber) {
                setProducts(p => [...p, partNumber])
            }
        }
    }

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = excel_file;
        link.download = 'dbs_quotation_sample.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className={`mt-10 md:mt-2 lg:mt-10 px-4 md:px-0`}>
            <div className="flex justify-between">
                <div className={`flex gap-2`}>
                    {["Part Number", "Excel File", "Vin Code", "Car Brand"].map((v) => (
                        <button
                            onClick={() => handleChange(v)}
                            className={`font-bold text-sm lg:text-base md:px-2 lg:px-5 px-3 py-2 lg:w-[150px] w-full rounded-full border-gray-300 border-[0.5px] ${tab === v ? "bg-primary text-white" : "bg-white text-black"
                                }`}
                        >
                            {v}
                        </button>
                    ))}
                </div>
            </div>
            <Box
                sx={{ bgcolor: "background.paper" }}
                className="rounded-3xl mt-4 lg:p-5 py-3"
            >
                {tab === "Excel File" ? (
                    <div className="flex flex-row w-full justify-end p-5 rounded-3xl">
                        <input
                            type="file"
                            placeholder="Enter Part No."
                            onChange={(e) => handleFileSelect(e)}
                            className="w-full py-1 text-md px-4 bg-gray-100 rounded-l-full focus:outline-none border border-gray-100 focus:border-gray-300
                        file:mr-4 file:py-[7px] file:px-6 file:rounded-full
                        file:border-0 file:text-sm file:font-semibold
                        file:bg-white file:text-black
                        hover:file:bg-primary hover:file:text-white"
                        />
                        <button onClick={() => ProceedFileRead()}
                            className="bg-primary px-2 w-32 py-1 rounded-r-full text-white font-semibold text-lg">
                            Proceed
                        </button>
                    </div>
                ) :
                    (
                        <div className="flex flex-row w-full justify-end p-5 rounded-3xl">
                            <input
                                onChange={(e) => handleInput(e.target.value, tab)}
                                type="text"
                                placeholder="Enter Part Number"
                                className="w-full py-2 text-md px-4 bg-gray-100 rounded-l-full focus:outline-none border border-gray-100 focus:border-gray-300" />
                            <button onClick={() => handleSearch(tab)}
                                className="bg-primary px-2 w-32 py-2 rounded-r-full text-white font-semibold text-lg">Search
                            </button>
                        </div>
                    )
                }

            </Box>
            <button className="px-3 py-1 rounded-md text-primary underline font-semibold mt-2"
                onClick={handleDownload}
            >Download Sample</button>
        </div>
    );
}
