import React from 'react'
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 12,
      fontWeight: "bold",
    },
  }));

const Tooltips = ({tooltip, title, children}) => {
  return (
    <LightTooltip title={title} arrow placement="top">
      {children}
    </LightTooltip>

  )
}

export default Tooltips