import React from "react";
import tyres from "../assests/cardImages/tyres.png";
import brakes from "../assests/cardImages/brakes.png";
import beltRollers from "../assests/cardImages/belt.png";
import carBody from "../assests/cardImages/chassis.png";
import clutch from "../assests/cardImages/vehicle.png";
import damping from "../assests/cardImages/shock-absorber.png";
import engine from "../assests/cardImages/car-engine.png";
import exhaust from "../assests/cardImages/muffler.png";
import filter from "../assests/cardImages/filter.png";
import oil from "../assests/cardImages/oil-tank.png";
import suspension from "../assests/cardImages/suspension.png";
import otherCategory from "../assests/cardImages/spare-parts.png";

const Cards = () => {
    const cardData = [
        {
            id: 1,
            title: "Tyres",
            image: tyres,
            description: "High-quality tyres for all vehicle types.",
        },
        {
            id: 2,
            title: "Brakes",
            image: brakes, // Replace with actual image path
            description: "Reliable and long-lasting batteries for vehicles.",
        },
        {
            id: 3,
            title: "Belt Rollers",
            image: beltRollers, // Replace with actual image path
            description: "Top-notch brake pads and discs for maximum safety.",
        },
        {
            id: 4,
            title: "Car Body",
            image: carBody, // Replace with actual image path
            description: "Advanced suspension systems for a smooth ride.",
        },
        {
            id: 5,
            title: "Clutch",
            image: clutch,
            description: "High-quality tyres for all vehicle types.",
        },
        {
            id: 6,
            title: "Damping",
            image: damping, // Replace with actual image path
            description: "Reliable and long-lasting batteries for vehicles.",
        },
        {
            id: 7,
            title: "Engine",
            image: engine, // Replace with actual image path
            description: "Top-notch brake pads and discs for maximum safety.",
        },
        {
            id: 8,
            title: "Exhaust",
            image: exhaust, // Replace with actual image path
            description: "Advanced suspension systems for a smooth ride.",
        },
        {
            id: 9,
            title: "Filter",
            image: filter,
            description: "High-quality tyres for all vehicle types.",
        },
        {
            id: 10,
            title: "Oil",
            image: oil, // Replace with actual image path
            description: "Reliable and long-lasting batteries for vehicles.",
        },
        {
            id: 11,
            title: "Suspension",
            image: suspension, // Replace with actual image path
            description: "Top-notch brake pads and discs for maximum safety.",
        },
        {
            id: 12,
            title: "Others",
            image: otherCategory, // Replace with actual image path
            description: "Advanced suspension systems for a smooth ride.",
        },
    ];

    return (
        <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 md:px-20 lg:px-32 px-5 py-20 gap-4">
            {cardData.map((item, index) => (
                <div key={index}
                     className="flex flex-col justify-center items-center hover:shadow-lg hover:shadow-gray-400 hover:scale-105 duration-300 bg-gradient-to-tr p-3 rounded-xl bg-white">
                    <img src={item.image} alt="img" className="h-20 w-20 md:m-4 p-4"/>
                    <h1 className="text-primary text-lg font-bold flex text-center justify-center">{item.title}</h1>
                </div>
            ))}
        </div>
    );
};

export default Cards;
