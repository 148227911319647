import React from 'react'
import { FaCheck, FaCheckDouble } from 'react-icons/fa6'

const SuccessPayment = () => {
  return (
    <div className='w-full h-screen flex justify-center items-center'>
        <form className='flex flex-col justify-center items-center content-center shadow-xl rounded-xl py-10 w-[50%]'>
        <FaCheck className="h-24 w-24 text-secondary p-4 rounded-full border-secondary border-4"/>
        <h1 className='text-2xl font-bold text-gray-600 text-center mt-5'>Payment Successful!</h1>
        <p className=' text-gray-400 mt-2'>Thank you for ordering</p>
        <div className='flex gap-2 mt-6'>
            <button className='text-center border rounded border-secondary text-secondary py-2 px-4 hover:bg-secondary hover:text-white duration-300 font-bold '>View Order</button>
            <button className='text-center bg-secondary text-white font-bold border hover:border-secondary hover:text-secondary rounded py-2 px-4 hover:bg-white duration-300 '>Continue Shopping</button>
        </div>
        </form>
    </div>
  )
}

export default SuccessPayment